<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="isDataLoadedFromServer"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Add New Review</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form id="createForm" class="pa-3 pb-0">
          <v-select
            v-model="formData.product_id"
            label="Product"
            item-text="name"
            item-value="id"
            :items="serverData.products"
            clearable
            outlined
            :error-messages="product_idErrors"
            @input="$v.formData.product_id.$touch()"
            @blur="$v.formData.product_id.$touch()"
          ></v-select>
          <v-select
            v-model="formData.status"
            label="Status"
            item-text="name"
            item-value="id"
            :items="serverData.statuses"
            clearable
            outlined
            :error-messages="statusErrors"
            @input="$v.formData.status.$touch()"
            @blur="$v.formData.status.$touch()"
          ></v-select>
          <v-text-field
            v-model="formData.name"
            label="Name"
            clearable
            outlined
            :error-messages="nameErrors"
            @input="$v.formData.name.$touch()"
            @blur="$v.formData.name.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="formData.email"
            label="Email"
            clearable
            outlined
            :error-messages="emailErrors"
            @input="$v.formData.email.$touch()"
            @blur="$v.formData.email.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="formData.review"
            label="Review"
            clearable
            outlined
            :error-messages="reviewErrors"
            @input="$v.formData.review.$touch()"
            @blur="$v.formData.review.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="formData.rating"
            label="Rating"
            type="number"
            max="5"
            clearable
            outlined
            :error-messages="ratingErrors"
            @input="$v.formData.rating.$touch()"
            @blur="$v.formData.rating.$touch()"
            @change="() => validateMinValue('rating', 0)"
          ></v-text-field>
        </form>

        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
// import { UPDATE_ENTITYCOLUMNS_DATA } from "@/core/services/store/entities.module";
import axios from "axios";
import { getToken } from "@/core/services/jwt.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required, email, maxValue } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  name: "AddItem",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      product_id: { required },
      name: { required },
      email: { required, email },
      review: { required },
      rating: { required, maxValue: maxValue(5) },
      status: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      product_id: null,
      name: null,
      email: null,
      review: null,
      rating: null,
      status: null,
    },
  }),
  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // console.log(vueObj.$v.formData[fieldName]);
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      if ("maxValue" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].maxValue &&
          errors.push(
            "This field shouldn't be bigger than " +
              vueObj.$v.formData[fieldName].$params.maxValue.max
          );
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let config = {
        method: "post",
        url: `${process.env.VUE_APP_BASE_URL}/stores/portal/reviews/create`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      };
      axios(config)
        .then((response) => {
          // console.log("response is ", response);
          this.serverData = response.data;
          this.serverData.statuses = [
            { name: "Enable", id: 1 },
            { name: "Disable", id: 0 },
          ];
          this.pageLoader(false);
          // console.log(response.data.entities);
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: `${error.response.data?.message}`,
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    toggleModal() {
      this.dialog = !this.dialog;
    },
    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if ((item[1] && item[0] != "image") || item[1] == 0) {
          data.append(item[0], item[1]);
        } else if (item[1] && item[0] == "image") {
          data.append(item[0], item[1][0]);
        }
      });

      return data;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.pageLoader(true);
        // let data = JSON.stringify(this.entCreate);
        let data = this.convertToFormData();
        let config = {
          method: "post",
          url: `${process.env.VUE_APP_BASE_URL}/stores/portal/reviews/store`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          data: data,
        };
        axios(config)
          .then(() => {
            Swal.fire({
              title: "Created",
              text: `Review successfully created!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });

            this.toggleModal();
            this.resetCreateForm();
            this.pageLoader(false);
            this.refresher();
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: `${error.response.data?.message}`,
              icon: "error",
              showConfirmButton: false,
              timer: 2500,
            });
            this.pageLoader(false);
          });
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        product_id: null,
        name: null,
        email: null,
        review: null,
        rating: null,
        status: null,
      };
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    product_idErrors: function () {
      return this.handleFormValidation("product_id", this);
    },
    nameErrors: function () {
      return this.handleFormValidation("name", this);
    },
    emailErrors: function () {
      return this.handleFormValidation("email", this);
    },
    reviewErrors: function () {
      return this.handleFormValidation("review", this);
    },
    ratingErrors: function () {
      return this.handleFormValidation("rating", this);
    },
    statusErrors: function () {
      return this.handleFormValidation("status", this);
    },
  },
};
</script>
